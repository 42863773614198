import React, { memo, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Navbar, NavDropdown, Nav } from "react-bootstrap";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import "../styles/components/header.scss";
import MDCC_Logo from "../assets/images/MDCC_Logo.png";
import TwitterLogo from "../assets/images/Twitter_Logo.png";
import DiscordLogo from "../assets/images/Discord-Logo.png";
import InstagramLogo from "../assets/images/Instagram_Logo.png";
import YoutubeLogo from "../assets/images/youtube_icon.svg";
import { useMedia } from "react-use";

// const linkData = [
//   {
//     title: "MAD DOGS",
//     url: "/mad-dogs",
//     dropdown: 1,
//     dropdownItem: [
//       ["ccc", "ccc"],
//       ["ccc", "ccc"],
//     ],
//   },
// ];

const Header = memo(
  ({ setMobileMenu }) => {
    const [menuOpened, setMenuOpened] = useState(false);
    const location = useLocation();
    const isMobile = useMedia("(max-width: 991px)");

    useEffect(() => {
      setMobileMenu(menuOpened);
    }, [menuOpened]);

    const handleRemoveMobileHeader = () => {
      if (isMobile) {
        setMenuOpened(false);
        document.querySelector("#basic-navbar-nav").style.display = "none";
      }
    };

    return (
      <Navbar className="nav-wrapper" expand="lg">
        <div className="header-container d-flex align-items-center justify-content-between">
          <Link className="navbar-home" to="/">
            {!menuOpened && (
              <img className="logo-image" src={MDCC_Logo} alt="mdcc-logo" />
            )}
          </Link>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setMenuOpened((value) => !value)}
          >
            {menuOpened ? (
              <AiOutlineClose size={36} color="#ffffff" />
            ) : (
              <AiOutlineMenu size={36} color="#ffffff" />
            )}
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto d-flex align-items-center justify-content-between flex-fill">
              {menuOpened && (
                <Link className="navbar-home" to="/">
                  <img
                    className="logo-image me-0"
                    src={MDCC_Logo}
                    alt="mdcc-logo"
                  />
                </Link>
              )}
              <NavDropdown
                title="MAD DOGS"
                id="navbarScrollingDropdown"
                className="font-nav font-bold"
              >
                <NavDropdown.Item href="/mad-dogs">ABOUT</NavDropdown.Item>
                <NavDropdown.Item href="/mad-dogs-ranking">
                  MAD DOGS RANKING
                </NavDropdown.Item>
                <NavDropdown.Item href="/mad-dogs-whitepaper">
                  WHITEPAPER
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title="MAD DOG CARS"
                id="navbarScrollingDropdown"
                className="font-nav font-bold"
              >
                <NavDropdown.Item href="/mad-dog-cars">ABOUT</NavDropdown.Item>
                <NavDropdown.Item href="/mad-dog-cars-whitepaper">
                  WHITEPAPER
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title="MD RACERS"
                id="navbarScrollingDropdown"
                className="font-nav font-bold"
              >
                <NavDropdown.Item href="/md-racers">ABOUT</NavDropdown.Item>
                <NavDropdown.Item href="/md-racers-ranking">
                  MD RACERS RANKING
                </NavDropdown.Item>
                {/* <NavDropdown.Item
                href="https://maddogcarclub.ada-anvil.io/en/"
                target="_blank"
              >
                MINT NOW
              </NavDropdown.Item>

              <NavDropdown.Item href="/check-allocation">
                CHECK ALLOCATION
              </NavDropdown.Item> */}
                <NavDropdown.Item href="/md-leaderboard">
                  LEADERBOARD
                </NavDropdown.Item>
                {/* <NavDropdown.Item href="/mint-process">
                MINT PROCESS
              </NavDropdown.Item> */}
                <NavDropdown.Item href="/md-racers-whitepaper">
                  WHITEPAPER
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="MD LICENSE"
                id="navbarScrollingDropdown"
                className="font-nav font-bold"
              >
                <NavDropdown.Item href="/md-license">ABOUT</NavDropdown.Item>
                <NavDropdown.Item href="/md-license-whitepaper">
                  WHITEPAPER
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="MD RACING"
                id="navbarScrollingDropdown"
                className="font-nav font-bold"
              >
                <NavDropdown.Item href="/md-racing">ABOUT</NavDropdown.Item>
                <NavDropdown.Item href="/md-racing-whitepaper">
                  WHITEPAPER
                </NavDropdown.Item>
                <NavDropdown.Item href="/md-racing-partners">
                  PARTNERS
                </NavDropdown.Item>
                <NavDropdown.Item href="/md-racing-bugreport">
                  BUG REPORT
                </NavDropdown.Item>
                <NavDropdown.Item href="/md-racing-pastgame">
                  GAME UPDATES
                </NavDropdown.Item>
              </NavDropdown>
              {/* <a
              href="https://mdtoken.io"
              className="font-nav font-bold text-nowrap"
              target="_blank"
            >
              MD TOKEN
            </a> */}
              <a href="/md-token" className="font-nav font-bold text-nowrap">
                MD TOKEN
              </a>

              <div className="social-panel d-flex align-items-center justify-content-center">
                <a
                  href="https://twitter.com/MadDogCarClub?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={TwitterLogo} alt="twitter-logo" />
                </a>
                <a
                  href="https://www.instagram.com/maddogcarclub/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={InstagramLogo} alt="instagram-logo" />
                </a>
                <a
                  href="https://discord.com/invite/maddogcarclub"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={DiscordLogo} alt="discord-logo" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCKoR9s7EOUWLvLLx0SyxKaQ"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={YoutubeLogo} alt="youtube-logo" />
                </a>
              </div>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    );
  });

export default Header;
