import React, { useState } from "react";
import "../../styles/whitepaper.scss";
const MDRacersWhitePaper = () => {
  return (
    <div className="whitepaper">
      <div className="container">
        <div className="title">
          <div className="font-70">
            MAD DOG RACERS<div className="font-30">WHITEPAPER</div>
          </div>
        </div>
        <div className="content">
          <div className="font-18">
            As we continue throughout our Roadmap, our Whitepaper will be
            updated. Any and all information on this page are subject to review,
            and nothing in this paper should be considered financial advice or
            used to inform financial or investment decisions. Mad Dog Car Club
            is not responsible for, or liable for any damages of any kind
            arising from the use of any information. Please make sure to always
            do your own research.
            <br />
            <br />
            This page has last been updated: 12/22/23
          </div>
          <div className="font-24">
            Released in December 2022 with a supply of 9,999 algorithmically
            generated racers.MD Racers is our latest and most sought-after
            release. These racers will be your unique identity within our game
            MD Racing. Each of these racers will be your ticket to our future
            metaverse MD Society. All in all, these racers have an immense
            utility that ties in greatly with the project's future and our game.
          </div>
          <div className="font-30">
            WHAT DO MAD DOGS DO? <br />
            <br />
            <div className="font-24 do-list">
              <div>
                <li>Non-Custodial Staking on MDToken.io</li>
                <li>
                  For every 5 Racers that you hold, you will be airdropped a
                  fully assembled car from every future car collection.
                </li>
                <li>
                  Every three months after the release of MD Racers, each asset
                  owner will be airdropped with an MD modification pack,
                  including exclusive car skins and other future car
                  accessories.
                </li>
                <li>Access to other metaverses</li>
                <li>Access to MD Racing</li>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MDRacersWhitePaper;
