import React, { useEffect, useState } from "react";
import "../../styles/madDogs.scss";
import MDLicenseImg from "../../assets/images/MDLicense.png";
import RoadMapMobile from "../../assets/images/2AMClub_Mobile_Roadmap.png";
import RoadMap from "../../assets/images/mad-dog-roadmap.png";
import Slick, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import numeral from "numeral";
import { useMedia } from "react-use";
import { Link } from "react-router-dom";
import MintModal from "../../components/MintModal";

const saleTexts = [
  {
    name: "Items",
    value: "9,999",
  },
  {
    name: "Owners",
    value: "1750",
  },
  {
    name: "Floor",
    value: "70",
  },
  {
    name: "Volume",
    value: "3.09m",
  },
];

const faqData = [
  {
    title: "HOW DO I GET AN MD LICENSE?",
    text: "MD License was airdropped to Dog Fathers and above (Owner of 20+ dogs) in September.",
  },
  {
    title: "What is the total supply?",
    text: "300 MD License",
  },
  {
    title: "When will the rest of the licenses be minted?",
    text: "They will be minted throughout giveaways, and competitions for our loyal MDCC Members.",
  },
  {
    title: "Will there be more perks for holding a MD License?",
    text: "Yes, there will be more perks added throughout the year.",
  },
];

const utilityData = [
  "Non-Custodial Staking on MDToken.io",
  "One free NFT from every future car collection",
  "Will Guarantee WL Spots for MDCC Releases",
  "20% Discount on MD Merchandise",
  "VIP Event Access for all MDCC Event ",
  "Access to exclusive giveaways & MD license lounge",
  "50% of monthly royalties to MD License Holders",
  "MD Racing In-game benefits",
  "Access to MD Racing",
];
const perksData = [
  "One free NFT from every future car collections",
  "Guaranteed WL Spots for MDCC Releases",
  "20% Discounts on MD Merchandise",
  "VIP Event Access for all MDCC Events",
  "Access to exclusive giveaways & MD License giveaways",
  "50% of monthly royalties from MD License",
  "MD Racing in game benifets",
];
const settings = {
  className: "center",
  centerMode: true,
  dots: false,
  infinite: true,
  centerPadding: "0px",
  speed: 500,
  slidesToShow: 3,
  // autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        centerPadding: "60px",
        slidesToShow: 2,
        autoplay: true,
        autoplaySpeed: 2000,
      },
    },
    {
      breakpoint: 550,
      settings: {
        centerPadding: "120px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 450,
      settings: {
        centerPadding: "80px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 320,
      settings: {
        centerPadding: "60px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 300,
      settings: {
        centerPadding: "20px",
        slidesToShow: 1,
      },
    },
  ],
};
const policyID = "757d6d0b72beba043054f920156b396db16e4f2492c6cec089ebd608";

const MdLicense = () => {
  const [saleData, setSaleData] = useState(saleTexts);
  const [assetsData, setAssetsData] = useState([]);
  const [highestSale, setHighestSale] = useState(0);
  const isMobile = useMedia("(max-width: 768px)");
  // const [showMintModal, setShowMintModal] = useState(true);
  const getPolicyData = async () => {
    const { data } = await axios.get(
      "https://api.opencnft.io/1/policy/" + policyID
    );
    saleData[0].value = data.asset_minted;
    saleData[1].value = data.asset_holders;
    saleData[2].value = data.floor_price / 1000000;
    saleData[3].value = data.total_volume / 1000000;
    setHighestSale(data.highest_sale.price / 1000000);
    setSaleData([...saleData]);
  };

  const getPolicyTransations = async () => {
    const { data } = await axios.get(
      "https://api.opencnft.io/1/policy/" + policyID + "/transactions"
    );
    setAssetsData(data.items);
  };

  useEffect(() => {
    getPolicyData();
    getPolicyTransations();
  }, []);

  return (
    <div className="md-license">
      <section className="about-section">
        <div className="container d-flex">
          <div className="d-flex align-items-end md-license-avatar">
            <img src={MDLicenseImg} alt="md-license-avatar" />
          </div>
          <div className="text-panel d-flex flex-column justify-content-center">
            <h1 className="font-title font-bold">MD LICENSE</h1>
            <h4 className="font-h4 font-medium">
              MD License is an identification in the MD Community which
              represents members who are loyal holders, and understand the
              vision and goal of MDCC.
            </h4>
            <h5 className="font-h5 font-light">
              The only way to obtain one of these license was to be airdropped
              one, or earn it through the community. Each license is static and
              allow the holder to obtain multiple perks in both the virtual and
              physical world.
            </h5>
            <div className="btn-panel d-flex align-items-center">
              <a
                href="md-license-whitepaper"
                className="btn btn-whitepaper font-nav font-bold d-flex align-items-center justify-content-center"
              >
                WHITEPAPER
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="sales-section">
        <div className="container d-flex flex-column align-items-center">
          <h1 className="font-h1 font-bold">LATEST SALES</h1>
          <div className="sale-text-panel d-flex">
            {saleData.map((saleText, index) => {
              let value = saleText.value;
              if (String(value).length > 4) {
                value = numeral(value).format("0.00 a");
              }
              return (
                <div className="sale-text-item text-center" key={index}>
                  <p className="sale-text_value font-normal">{value}</p>
                  <p className="sale-text_label font-light">{saleText.name}</p>
                </div>
              );
            })}
          </div>
          <a
            target="_blank"
            href="https://www.jpg.store/collection/maddogcarclub-mdlicense"
            className="visit-btn font-button2 font-bold"
            rel="noreferrer"
          >
            Visit JPG.STORE
          </a>
        </div>
        <div className="slick-container container">
          <Slick {...settings}>
            {assetsData.map((item, index) => {
              const imageId = item?.thumbnail.thumbnail.replaceAll(
                "ipfs://",
                ""
              );
              let seconds = (+new Date() - item?.sold_at * 1000) / 1000;
              let time = "";
              if (seconds / 3600 < 1) {
                time =
                  time +
                  ((seconds % 3600) / 60).toFixed() +
                  (((seconds % 3600) / 60).toFixed() ? " minutes" : " minute");
              } else {
                if (seconds / 3600 > 23) {
                  time =
                    (seconds / 3600 / 24).toFixed() +
                    ((seconds / 3600 / 24).toFixed() > 1 ? " days " : " day ");
                }
                time =
                  time +
                  ((seconds % (3600 * 24)) / 3600).toFixed() +
                  (((seconds % (3600 * 24)) / 3600).toFixed() > 1
                    ? " hours "
                    : " hour ");
                time =
                  time +
                  (((seconds % (3600 * 24)) % 3600) / 60).toFixed() +
                  " minutes";
              }
              return (
                <div
                  className="slick-item d-flex flex-column align-items-center"
                  key={index}
                >
                  <div className="img-panel">
                    <img
                      src={`https://image-optimizer.jpgstoreapis.com/${imageId}/?width=600`}
                      alt="dog-avatar"
                    />
                  </div>
                  <p className="slick-text font-light text-center">
                    Sold {time} ago
                  </p>
                </div>
              );
            })}
          </Slick>
        </div>
        <div className="utility-panel">
          <div className="container d-flex">
            <div className="utility-text-panel w-50">
              <h1 className="font-title font-bold">UTILITY</h1>
              <ul>
                {utilityData.map((text, index) => (
                  <li key={index} className="font-h5 font-light">
                    {text}
                  </li>
                ))}
              </ul>
            </div>
            <div className="perks-text-panel w-50">
              <h1 className="font-title font-bold">PERKS</h1>
              <ul>
                {perksData.map((text, index) => (
                  <li key={index} className="font-h5 font-light">
                    {text}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="road-map-section">
        <div className="container">
          <img src={isMobile ? RoadMapMobile : RoadMap} alt="roadmap" />
        </div>
      </section>
      <section className="faq-section">
        <div className="container">
          <h1 className="font-h1 font-bold text-center">
            FREQUENTLY ASKED QUESTIONS
          </h1>
          <div className="faq-text-panel">
            {faqData.map((faqItem, index) => (
              <div className="faq-item" key={index}>
                <h3 className="font-button1 font-bold">{faqItem.title}</h3>
                <p className="font-nav font-light">{faqItem.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <MintModal show={showMintModal} onClose={() => setShowMintModal(false)} /> */}
    </div>
  );
};

export default MdLicense;
