import React, { useEffect, useState } from "react";
import "../../styles/madDogsRanking.scss";
import SearchIcon from "../../assets/images/search_icon.svg";

import nftData from "../../assets/nftData.json";
import MadDogDetailModal from "../../components/MadDogDetailModal";
// import MintModal from "../../components/MintModal";
const MadDogsRanking = () => {
  const [filterItem, setFilterItem] = useState([]);
  const [activeFilterItems, setActiveFilterItems] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [data, setData] = useState([]);
  const [imgUrl, setimgUrl] = useState([]);

  const [showMadDogDetailModal, setShowMadDogDetailModal] = useState(false);

  const showModal = (item, imgUrl) => {
    setData(item);
    setimgUrl(imgUrl);
    setShowMadDogDetailModal(true);
  };

  const handleActiveItems = (value) => {
    const sameTypeItem = activeFilterItems.find((item) =>
      item.includes(value.split("-")[0])
    );
    console.log("sameTypeItem", sameTypeItem);
    if (activeFilterItems.includes(value)) {
      setActiveFilterItems([
        ...activeFilterItems.filter((item) => item !== value),
      ]);
    } else {
      if (sameTypeItem) {
        setActiveFilterItems([
          ...activeFilterItems.filter((item) => item !== sameTypeItem),
          value,
        ]);
      } else {
        setActiveFilterItems([...activeFilterItems, value]);
      }
    }
  };

  const getFilterItems = () => {
    const arr = [];
    Object.keys(nftData.attributes).map((item) => {
      arr.push({ title: item, items: Object.keys(nftData.attributes[item]) });
    });
    setFilterItem([...arr]);
  };

  const getAssetsData = () => {
    setAssetData([
      ...nftData.stats.sort((a, b) => +a.rarityRank - +b.rarityRank),
    ]);

    setSearchData([
      ...nftData.stats.sort((a, b) => +a.rarityRank - +b.rarityRank),
    ]);
  };

  const handleSearch = () => {
    if (searchValue) {
      setAssetData([
        ...searchData.filter((item) => item.name.includes(searchValue)),
      ]);
    } else {
      setAssetData([...searchData]);
    }
  };

  useEffect(() => {
    getFilterItems();
    getAssetsData();
  }, []);

  useEffect(() => {
    if (activeFilterItems.length) {
      let arr1 = nftData.stats.sort((a, b) => +a.rarityRank - +b.rarityRank);
      let arr2;
      activeFilterItems.map((item) => {
        arr2 = [];
        arr2.push(
          ...arr1.filter(
            (asset) => asset[item.split("-")[0]] === item.split("-")["1"]
          )
        );
        arr1 = arr2;
      });
      setAssetData([...arr2]);
      setSearchData([...arr2]);
    } else {
      setAssetData([
        ...nftData.stats.sort((a, b) => +a.rarityRank - +b.rarityRank),
      ]);
      setSearchData([
        ...nftData.stats.sort((a, b) => +a.rarityRank - +b.rarityRank),
      ]);
    }
  }, [activeFilterItems]);

  return (
    <div className="md-ranking">
      <div className="container">
        <div className="d-flex justify-content-between">
          <div className="filter-panel">
            <div className="filter-field d-flex align-items-center">
              <button className="btn btn-filter font-nav font-bold">
                FILTERS
              </button>
            </div>
            <div className="filter-items-panel">
              {filterItem.map((filterData, index) => (
                <div className="filter-items" key={index}>
                  <h3 className="filter-item-title font-bold">
                    {filterData.title}
                  </h3>
                  <div className="filter-item-panel d-flex flex-wrap">
                    {filterData.items.map((item, i) => (
                      <div className="filter-item-cover" key={i}>
                        <button
                          className={
                            "filter-item d-flex align-items-center justify-content-center " +
                            (activeFilterItems.find(
                              (element) =>
                                element === filterData.title + "-" + item
                            ) && "active")
                          }
                          onClick={() =>
                            handleActiveItems(filterData.title + "-" + item)
                          }
                        >
                          {item}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="main-panel">
            <h1 className="font-h1 font-bold">MAD DOGS RANKING</h1>
            <div className="search-field d-flex align-items-center">
              <label className="search-input-field">
                <input
                  className="search-input font-nav"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <img
                  src={SearchIcon}
                  className="search-icon"
                  alt="search-icon"
                />
              </label>
              <button
                className="btn btn-search font-nav font-bold"
                onClick={handleSearch}
              >
                SEARCH
              </button>
            </div>
            <div className="product-panel d-flex justify-content-between flex-wrap">
              {assetData.slice(0, 30).map((item, index) => {
                let imgUrl = item.url.replaceAll("ipfs://", "");
                return (
                  <div
                    className="product-item"
                    key={index}
                    onClick={() => showModal(item, imgUrl)}
                  >
                    <img
                      src={`https://image-optimizer.jpgstoreapis.com/${imgUrl}/?width=196`}
                      alt="product-img"
                    />
                    <p className="product-text font-bold text-center">
                      {item.name}
                    </p>
                    <p className="product-text font-bold text-center">
                      #Rank {item.rarityRank}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <MadDogDetailModal
          show={showMadDogDetailModal}
          onClose={() => setShowMadDogDetailModal(false)}
          data={data}
          imgUrl={imgUrl}
        />
      </div>
    </div>
  );
};

export default MadDogsRanking;
