import React from "react";
import { Link } from "react-router-dom";
import "../styles/components/footer.scss";

const Footer = () => {
  return (
    <footer className="footer text-center">
      © MAD DOG CAR CLUB <br />
      ALL RIGHTS RESERVED <br />
      <Link to="/terms">TERMS</Link> & <Link to="privacy">POLICY</Link>
    </footer>
  );
};

export default Footer;
