import React, { useState } from "react";
import "../../styles/mdToken.scss";
import MDTokenLogo from "../../assets/images/MDToken_Logo_Horixontal White 1.png";
import PlatformImg from "../../assets/images/platform_img.png";
import EllipseImg5 from "../../assets/images/Ellipse 5.png";
import EllipseImg4 from "../../assets/images/Ellipse 4.png";
import EllipseImg7 from "../../assets/images/Ellipse 7.png";
import EllipseImg8 from "../../assets/images/Ellipse 8.png";
import EllipseImg9 from "../../assets/images/Ellipse 9.png";
import EllipseImg10 from "../../assets/images/Ellipse 10.png";
import EllipseImg11 from "../../assets/images/Ellipse 11.png";
import EllipseImg12 from "../../assets/images/Ellipse 12.png";
import EllipseImg13 from "../../assets/images/Ellipse 13.png";
import EllipseImg14 from "../../assets/images/Ellipse 14.png";
import MintModal from "../../components/MintModal";

const platformText = [
  {
    title: "NON CUSTODIAL STAKING",
    text: "MD Token is the premier destination for non-custodial staking, offering users the ability to earn generous rewards in the form of $MD, the native utility and governance token of MD Token and MDCC by holding MDCC assets or Partnership Project assets. With our non-custodial staking feature, your NFTs will remain securely in your own wallet, giving you complete control and the ability to remove your staked assets at any time. This innovative approach to staking allows users to earn rewards while maintaining full ownership of their assets, making MD Token the leading platform for non-custodial staking in the industry.",
  },
  {
    title: "ON CHAIN RAFFLES",
    text: "MD Token is a pioneering platform on the Cardano blockchain, being one of the first to implement a Raffle System utilizing 4 Plutus V2 Smart contracts. This innovative approach is fully transparent, secure, and automated, ensuring that users can participate in the raffle with complete confidence and peace of mind. With its cutting-edge technology, MD Token is leading the way in utilizing the full potential of the Cardano blockchain to create new and exciting opportunities for its users.",
  },
  {
    title: "SMART CONTRACTS",
    text: "MD Token is poised to revolutionize the way we use Smart Contracts and other cutting-edge technology on the Cardano Blockchain. As a trailblazer in the industry, MD Token is dedicated to exploring and implementing new and innovative methods to fully harness the potential of this powerful decentralized platform. With a commitment to staying at the forefront of technology advancements, MD Token is set to become the go-to destination for anyone looking to tap into the full potential of the Cardano Blockchain.",
  },
];

const MDToken = () => {
  // const [showMintModal, setShowMintModal] = useState(true);
  return (
    <div className="md-token">
      <section className="about-section">
        <div className="container d-flex">
          <div className="d-flex flex-column align-items-center md-racing-logo">
            <img src={MDTokenLogo} alt="md-racing-logo" />

            <div className="btn-panel d-flex flex-column justify-content-center align-items-center">
              <a
                href="https://mdtoken.io"
                target="_blank"
                className="btn btn-takeme font-nav font-bold d-flex align-items-center justify-content-center"
              >
                TAKE ME THERE
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="sales-section">
        <div className="text-panel container d-flex flex-column align-items-center">
          <p className="font-button1 font-bold text-center">
            MD TOKEN is fundamentally designed as a native utility and
            governance token.
          </p>
        </div>
      </section>
      <section className="platform-section">
        <div className="container">
          <h1 className="font-h1 font-bold text-center">THE PLATFORM</h1>
          <div className="text-panel d-flex justify-content-between">
            {platformText.map((item, index) => (
              <div className="text-item flex-fill" key={index}>
                <h4 className="font-h4 font-medium">{item.title}</h4>
                <h5 className="font-h5 font-light">{item.text}</h5>
              </div>
            ))}
          </div>
          <img className="platform-img" src={PlatformImg} alt="platform-img" />
        </div>
      </section>
      <section className="utility-section">
        <div className="container">
          <h1 className="font-h1 font-bold text-center">UTILITY</h1>
          <h5 className="font-h5 font-light text-center">
            Holding MD Tokens will give you membership to the MD DAO, and will
            pioneer community-led governance on the Cardano Blockchain. With it
            being the first of its kind, our token will facilitate decentralized
            governance, growth, and the future outlook of the MD Token.
          </h5>
        </div>
      </section>
      <section className="partnership-section">
        <div className="container">
          <div className="text-panel">
            <h1 className="font-h1 font-bold text-center">
              MD TOKEN V2 PARTNERSHIPS!
            </h1>
            <h5 className="font-h5 font-light text-center">
              Being one of the first non-custodial staking platforms on the
              Cardano Blockchain, we have received many applications to become a
              partner with us. For the first partnership launch in v2 of md
              token, we have accepted 10 projects that can stake with us in
              order to earn md tokens.
            </h5>
          </div>
          <div className="img-group">
            <div className="img-panel d-flex align-items-center justify-content-center">
              <img src={EllipseImg4} alt="ellipse-img" />
              <img src={EllipseImg5} alt="ellipse-img" />
            </div>
            <div className="img-panel d-flex align-items-center justify-content-center">
              <img src={EllipseImg7} alt="ellipse-img" />
              <img src={EllipseImg8} alt="ellipse-img" />
              <img src={EllipseImg9} alt="ellipse-img" />
            </div>
            <div className="img-panel d-flex align-items-center justify-content-center">
              <img src={EllipseImg10} alt="ellipse-img" />
              <img src={EllipseImg11} alt="ellipse-img" />
              <img src={EllipseImg12} alt="ellipse-img" />
            </div>
            <div className="img-panel d-flex align-items-center justify-content-center">
              <img src={EllipseImg13} alt="ellipse-img" />
              <img src={EllipseImg14} alt="ellipse-img" />
            </div>
          </div>
        </div>
      </section>
      {/* <MintModal show={showMintModal} onClose={() => setShowMintModal(false)} /> */}
    </div>
  );
};

export default MDToken;
