import React, { useState } from "react";
import "../../styles/whitepaper.scss";
const MadDogCarsWhitePaper = () => {
  return (
    <div className="whitepaper">
      <div className="container">
        <div className="title">
          <div className="font-70">
            MAD DOG CARS<div className="font-30">WHITEPAPER</div>
          </div>
        </div>
        <div className="content">
          <div className="font-18">
            As we continue throughout our Roadmap, our Whitepaper will be
            updated. Any and all information on this page are subject to review,
            and nothing in this paper should be considered financial advice or
            used to inform financial or investment decisions. Mad Dog Car Club
            is not responsible for, or liable for any damages of any kind
            arising from the use of any information. Please make sure to always
            do your own research.
            <br />
            <br />
            This page has last been updated: 12/22/23
          </div>
          <div className="font-24">
            Our second sold-out collection, released in April 2022, introduced
            the building blocks for our game MD Racing. With each dog, there
            would need to be a car to race in. This customizable collection gave
            users a variety of Car Parts that they would need to put together.
            Once you have acquired a chassis, engine, and wheels, your car will
            be fully assembled and ready to race. MD Racing is not the only
            benefit of these car parts; we gave utility a whole new meaning with
            this collection.
          </div>
          <div className="font-30">
            WHAT DO MAD DOGS DO? <br />
            <br />
            <div className="font-24 do-list">
              <div>
                <li>
                  Staking benefits within MDToken.io, CardanoLands, and Ape
                  Society
                </li>
                <li>50% of Monthly Royalties to holders of 20+ Mad Dogs</li>
                <li>
                  In-game Asset where you, as the player, are the sole owner of
                  this car
                </li>
                <li>Customizable vehicle in our game </li>
                <li>Access to MD Racing</li>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MadDogCarsWhitePaper;
