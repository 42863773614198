import React, { useState } from "react";
import "../../styles/updates.scss";
const PastGame = () => {
  return (
    <div className="updates">
      <div className="container">
        <div className="font-70">PAST GAME UPDATES</div>
        <br />
        <div className="font-24">
          The following bugs reported have been fixed for V1.02.
        </div>
        <div className="content">
          <div className="font-30 content-date">JANUARY 2023</div>
          <div className="font-24">
            <li>
              Audio Setting issues, scrolling down to the dropdown shows grayed
              out video resolutions (unable to change)
            </li>{" "}
            <li>Unable to select a dog in the garage</li>
            <li>
              When choosing the k9RS it shows the Taurus Camion. The Taurus
              Camion is un-drivable and flips over easily.
            </li>
            <li>
              Same with display settings, not able to show other options in the
              dropdown.
            </li>{" "}
            <li>Upgrade - Spelling issue in the Upgrade Car settings.</li>{" "}
            <li>
              Car speed, acceleration, handling, and braking labels at the
              bottom of the page overlap with numbers.
            </li>{" "}
            <li>
              (Logo - Victory!) from the last race is stuck on screen after
              starting a new race and new map. Went from MD Circuit to Desert.
            </li>
            <li>
              Once on the racetrack map there are delayed inputs when driving
              and glitching when steering. (Desert and Snow is okay)
            </li>{" "}
            <li>Pressing “Q” is delayed and does not work often.</li>{" "}
            <li>
              Not easy to Quit the game while in the starting screen. (Players
              must use ALT+F4)
            </li>{" "}
            <li>Make a quit option in the starting screen.</li>
            <li>
              Sound effects for some cars come first before seeing the car.
            </li>{" "}
            <li>
              Not able to drag the game into separate screens if using different
              monitors.
            </li>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PastGame;
