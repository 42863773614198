import React, { useEffect, useState } from "react";
import "../../styles/madDogs.scss";
import CentarioAvatar from "../../assets/images/Centario_Transparent.png";
import DogImage from "../../assets/images/Rectangle.png";
import RoadMapMobile from "../../assets/images/2AMClub_Mobile_Roadmap.png";
import RoadMap from "../../assets/images/mad-dog-roadmap.png";
import Slick, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import numeral from "numeral";
import { useMedia } from "react-use";
import { Link } from "react-router-dom";
import nftData from "../../assets/nftData.json";
import MintModal from "../../components/MintModal";

const saleTexts = [
  {
    name: "Items",
    value: "9,999",
  },
  {
    name: "Owners",
    value: "1750",
  },
  {
    name: "Floor",
    value: "70",
  },
  {
    name: "Volume",
    value: "3.09m",
  },
];

const faqData = [
  {
    title: "When did Mad Dogs release?",
    text: "The first collection, Mad Dogs, was released in April of 2022.",
  },
  {
    title: "What was the mint price?",
    text: "The mint price was 30 ADA for both Whitelist and the Public sale.",
  },
  {
    title: "What is the total supply of Mad Dogs?",
    text: "The total supply of Mad Dog is 9,999.",
  },
  {
    title: "What is the main utility of a Mad Dog?",
    text: "There are many different utilities you acquire with holding a Mad Dog. It is your ticket to play our game MD Racing which V1.01 will be released in November! Some other utilities include royalties paid out monthly from collection volume, MD Token accumulation, improvements to cars statistics, in-game achievements for MD Tokens and NFT airdrops, WL opportunities, and holder role benefits. ",
  },
];

const utilityData = [
  "Non-Custodial Staking on MDToken.io",
  "Non-Custodial Staking on Cardano Lands",
  "Non-Custodial Staking on Ape Society",
  "50% of Monthly Royalties to all Top 1K holders",
  "PFP Utilization",
  "Benifets towards all Future Whitelists",
  "Access to MD Racing Game",
];

const settings = {
  className: "center",
  centerMode: true,
  dots: false,
  infinite: true,
  centerPadding: "0px",
  speed: 500,
  slidesToShow: 3,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        centerPadding: "60px",
        slidesToShow: 2,
        autoplay: true,
        autoplaySpeed: 2000,
      },
    },
    {
      breakpoint: 550,
      settings: {
        centerPadding: "120px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 450,
      settings: {
        centerPadding: "80px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 320,
      settings: {
        centerPadding: "60px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 300,
      settings: {
        centerPadding: "20px",
        slidesToShow: 1,
      },
    },
  ],
};
const policyID = "7e605894cdcbe8d1933f0a8760d93bd121aca583cf21ad1c25e51fd3";

const MadDogs = () => {
  const [saleData, setSaleData] = useState(saleTexts);
  const [assetsData, setAssetsData] = useState([]);
  const [highestSale, setHighestSale] = useState(0);
  const isMobile = useMedia("(max-width: 768px)");
  // const [showMintModal, setShowMintModal] = useState(true);
  const getPolicyData = async () => {
    const { data } = await axios.get(
      "https://api.opencnft.io/1/policy/" + policyID
    );
    saleData[0].value = data.asset_minted;
    saleData[1].value = data.asset_holders;
    saleData[2].value = data.floor_price / 1000000;
    saleData[3].value = data.total_volume / 1000000;
    setHighestSale(data.highest_sale.price / 1000000);
    setSaleData([...saleData]);
  };

  const getPolicyTransations = async () => {
    const { data } = await axios.get(
      "https://api.opencnft.io/1/policy/" + policyID + "/transactions"
    );
    setAssetsData(data.items);
  };

  useEffect(() => {
    getPolicyData();
    getPolicyTransations();
    console.log(nftData);
  }, []);

  return (
    <div className="mad-dogs-page">
      <section className="about-section">
        <div className="container d-flex">
          <div className="d-flex align-items-end centario-avatar">
            <img src={CentarioAvatar} alt="centario-avatar" />
          </div>
          <div className="text-panel d-flex flex-column justify-content-center">
            <h1 className="font-title font-bold">MAD DOGS</h1>
            <h4 className="font-h4 font-medium">
              A sold-out collection launched in April 2022, consisting of 9,999
              unique algorithmically generated dogs that are speeding around the
              Cardano blockchain with the meanest cars on the block.
            </h4>
            <h5 className="font-h5 font-light">
              Our focus with our first launch was not just a brand in the
              virtual world, but rather a brand in the real world. Owning a Mad
              Dog gives you exclusive access to a plethora of benefits in both
              real and the virtual world.
            </h5>
            <div className="btn-panel d-flex align-items-center">
              {/* <button className="btn btn-whitepaper font-nav font-bold d-flex align-items-center justify-content-center">WHITEPAPER</button> */}
              <Link
                to="/mad-dogs-ranking"
                className="btn btn-ranking font-nav font-bold d-flex align-items-center justify-content-center"
              >
                RANKING
              </Link>
              <Link
                to="/mad-dogs-whitepaper"
                className="btn btn-ranking font-nav font-bold d-flex align-items-center justify-content-center"
              >
                WHITEPAPER
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="sales-section">
        <div className="container d-flex flex-column align-items-center">
          <h1 className="font-h1 font-bold">LATEST SALES</h1>
          <div className="sale-text-panel d-flex">
            {saleData.map((saleText, index) => {
              let value = saleText.value;
              if (String(value).length > 4) {
                value = numeral(value).format("0.00 a");
              }
              return (
                <div className="sale-text-item text-center" key={index}>
                  <p className="sale-text_value font-normal">{value}</p>
                  <p className="sale-text_label font-light">{saleText.name}</p>
                </div>
              );
            })}
          </div>
          <a
            target="_blank"
            href="https://www.jpg.store/collection/maddogcarclub"
            className="visit-btn font-button2 font-bold"
            rel="noreferrer"
          >
            Visit JPG.STORE
          </a>
        </div>
        <div className="slick-container container">
          <Slick {...settings}>
            {assetsData.map((item, index) => {
              const imageId = item?.thumbnail.thumbnail.replaceAll(
                "ipfs://",
                ""
              );
              let seconds = (+new Date() - item?.sold_at * 1000) / 1000;
              let time = "";
              if (seconds / 3600 < 1) {
                time =
                  time +
                  ((seconds % 3600) / 60).toFixed() +
                  (((seconds % 3600) / 60).toFixed() ? " minutes" : " minute");
              } else {
                if (seconds / 3600 > 23) {
                  time =
                    (seconds / 3600 / 24).toFixed() +
                    ((seconds / 3600 / 24).toFixed() > 1 ? " days " : " day ");
                }
                time =
                  time +
                  ((seconds % (3600 * 24)) / 3600).toFixed() +
                  (((seconds % (3600 * 24)) / 3600).toFixed() > 1
                    ? " hours "
                    : " hour ");
                time =
                  time +
                  (((seconds % (3600 * 24)) % 3600) / 60).toFixed() +
                  " minutes";
              }
              return (
                <div
                  className="slick-item d-flex flex-column align-items-center"
                  key={index}
                >
                  <img
                    src={`https://image-optimizer.jpgstoreapis.com/${imageId}/?width=600`}
                    alt="dog-avatar"
                  />
                  <p className="slick-text font-light text-center">
                    Sold {time} ago
                  </p>
                </div>
              );
            })}
          </Slick>
        </div>
        <div className="utility-panel">
          <div className="container d-flex">
            <div className="utility-text-panel w-50">
              <h1 className="font-title font-bold">UTILITY</h1>
              <ul>
                {utilityData.map((text, index) => (
                  <li key={index} className="font-h5 font-light">
                    {text}
                  </li>
                ))}
              </ul>
            </div>
            <div className="utility-item-panel w-50 d-flex flex-column align-items-end">
              <div className="utility-item font-medium">
                Highest Sale: {highestSale} ADA
              </div>
              <div className="utility-item font-medium">
                Total Royalties Distributed: 76,069 ADA
              </div>
              <div className="utility-item font-medium">
                Total Staking Platforms: 3
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="road-map-section">
        <div className="container">
          <img src={isMobile ? RoadMapMobile : RoadMap} alt="roadmap" />
        </div>
      </section>
      <section className="faq-section">
        <div className="container">
          <h1 className="font-h1 font-bold text-center">
            FREQUENTLY ASKED QUESTIONS
          </h1>
          <div className="faq-text-panel">
            {faqData.map((faqItem, index) => (
              <div className="faq-item" key={index}>
                <h3 className="font-button1 font-bold">{faqItem.title}</h3>
                <p className="font-nav font-light">{faqItem.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <MintModal show={showMintModal} onClose={() => setShowMintModal(false)} /> */}
    </div>
  );
};

export default MadDogs;
