import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import axios from "axios";
import fileDownload from "js-file-download";

import "../styles/components/MadDogDetailModal.scss";
// const fileDownload = require('react-file-download');
const MadDogDetailModal = ({ show, onClose, data, imgUrl }) => {
  const download = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-mddog-detail"
    >
      <button
        type="button"
        className="btn-close btn-cancel"
        aria-label="Close"
        onClick={onClose}
      ></button>

      <Modal.Body className="modal-body">
        {data && (
          <Row className="gx-5">
            <Col lg={6} sm={12}>
              <Row>
                <img
                  src={`https://image-optimizer.jpgstoreapis.com/${imgUrl}/?width=196`}
                  alt="product-img"
                  width="100%"
                  height="100%"
                />
              </Row>
              <div className="d-flex justify-content-center">
                {imgUrl && (
                  <button
                    className="btn-download"
                    onClick={() =>
                      download(
                        `https://image-optimizer.jpgstoreapis.com/${imgUrl}`,
                        `${data.name}.png`
                      )
                    }
                  >
                    DOWNLOAD
                  </button>
                )}
              </div>
            </Col>
            <Col lg={6} sm={12}>
              <div className="maddog-name">{data.name}</div>
              <Table className="token-table">
                <tbody>
                  <tr>
                    <td>Fur</td>
                    <td></td>
                    <td>{data.fur}</td>
                    <td>{"  "}</td>
                  </tr>
                  <tr>
                    <td>Eyes</td>
                    <td></td>
                    <td>{data.eyes}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Clothes</td>
                    <td></td>
                    <td>{data.clothes}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Hat</td>
                    <td></td>
                    <td>{data.hat}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Mouth</td>
                    <td></td>
                    <td>{data.mouth}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Background</td>
                    <td></td>
                    <td>{data.background}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Rank</td>
                    <td></td>
                    <td>{data.rarityRank}</td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default MadDogDetailModal;
