import React, { useState } from "react";
import "../../styles/whitepaper.scss";
const MDLicenseWhitepaper = () => {
  return (
    <div className="whitepaper">
      <div className="container">
        <div className="title">
          <div className="font-70">
            MD LICENSE<div className="font-30">WHITEPAPER</div>
          </div>
        </div>
        <div className="content">
          <div className="font-18">
            As we continue throughout our Roadmap, our Whitepaper will be
            updated. Any and all information on this page are subject to review,
            and nothing in this paper should be considered financial advice or
            used to inform financial or investment decisions. Mad Dog Car Club
            is not responsible for, or liable for any damages of any kind
            arising from the use of any information. Please make sure to always
            do your own research.
            <br />
            <br />
            This page has last been updated: 12/22/23
          </div>
          <div className="font-24">
            MD License is our identification within the Mad Dog community. This
            license will represent our most loyal, and VIP holders who genuinely
            understand the vision and goals of MDCC. The licenses were only
            handed to those airdropped or previously earned through the
            community. Each license is static, allowing the holder to gain great
            utilities that will be immensely beneficial in the future.
          </div>
          <div className="font-30">
            WHAT DO MAD DOGS DO? <br />
            <br />
            <div className="font-24 do-list">
              <div>
                <li>Non-Custodial Staking on MDToken.io</li>
                <li>One free NFT from every future car collection</li>
                <li> Will Guarantee WL Spots for MDCC Releases</li>
                <li>20% Discount on MD Merchandise</li>
                <li>VIP Event Access for all MDCC Event</li>
                <li>Access to exclusive giveaways & MD license lounge</li>
                <li>50% of monthly royalties to MD License Holders</li>
                <li>MD Racing In-game benefits</li>
                <li>Access to MD Racing</li>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MDLicenseWhitepaper;
