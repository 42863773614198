import React, { useState } from "react";
import "../../styles/mdRacingWhitepaper.scss";
const MDRacingWhitePaper = () => {
  return (
    <div className="whitepaper">
      <div className="container">
        <div className="title">
          <div className="font-70">
            MD RACING<div className="font-30">WHITEPAPER</div>
          </div>
        </div>
        <div className="font-18">
          As we continue throughout our Roadmap, our Whitepaper will be updated.
          Any and all information on this page are subject to review, and
          nothing in this paper should be considered financial advice or used to
          inform financial or investment decisions. Mad Dog Car Club is not
          responsible for, or liable for any damages of any kind arising from
          the use of any information. Please make sure to always do your own
          research.
          <br />
          <br />
          This page has last been updated: 12/22/23
        </div>
        <div className="content font-24">
          <div>
            <div>
              MD Racing is our staple UE5-developed blockchain P2E Racing game.
              This is where players will have complete asset ownership and a
              chance to earn MD Tokens while racing other holders. Using our
              unique blockchain integration, you can customize and use your NFTs
              within the game.
            </div>
            <br />
            <div className="do-list">
              There will be three main game modes:
              <li>
                <span className="bold">Practice:</span> This game mode will
                allow users to play solo and build their skills when it comes to
                racing. Users will be able to get familiar with the cars they
                own and the tracks that they race on.
              </li>
              <li>
                <span className="bold">Unranked:</span> This multiplayer game
                mode will allow users to play against others for fun and earn
                achievements for their MD Racers profiles. The unranked mode
                will include 4, 6, and 8-player races.
              </li>
              <li>
                <span className="bold">Ranked:</span> This multiplayer game mode
                will be for skilled users who want to earn big. Players can use
                their MD Tokens to stake into races against other players. There
                will be different tiers depending on the skill of the player. We
                will have a monthly leaderboard that will display the players
                who have won the most races in ranked mode (top players for the
                month will be rewarded). The ranked mode will include 4, 6, and
                8-player races.
              </li>
            </div>
            <br />
            <div>
              Some essential facts to be noted for MD Racing as a whole:
              <li>
                MD Modification Packs will be an important way of earning car
                accessories, such as Car Skins which help improve your car.
              </li>
              <li>
                In the future, we plan to release more car accessories such as
                wheels, spoilers, engines, and more! Each car accessory will be
                based on 4 Tiers, Diamond, Gold, Silver, and Bronze. To earn MD
                Modification Packs, you must be holding MD Racers. Every three
                months, each MD Racer held will receive 1 MD Modification
                Package
              </li>
              <li>
                We also plan on releasing a Rent-a-Car feature for those who
                hold many cars that are not in use. Holders can put their
                vehicles up for rent and give opportunities to other users who
                don't own cars or want to try a new vehicle to test and play. We
                plan on releasing this update in Q3 of 2023.
              </li>
            </div>
            <br />
            <div>
              Right now, the only way to upgrade your car statistics is through
              owning Mad Dogs or Car Skin NFTs.
            </div>
          </div>
        </div>
        <div className="btn-download">
          <div>
            <button>
              <a
                href="https://drive.google.com/file/d/1UALhnX2olCHjQVwKgp_BoMvLT_HtJfNA/view?usp=share_link"
                target="_blank"
              >
                DOWNLOAD GAME
              </a>
            </button>
            <p>**V1.02 Windows Compatible Only**</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MDRacingWhitePaper;
