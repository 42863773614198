import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-use";
import Footer from "./footer";
import Header from "./header";

const MainLayout = () => {
  const location = useLocation();
  const [mobileMenuEnable, setMobileMenu] = useState(false);
  return (
    <>
      {location.pathname === "/" ? (
        ""
      ) : (
        <Header setMobileMenu={setMobileMenu} />
      )}
      {!mobileMenuEnable && <Outlet />}
      {location.pathname === "/" ? "" : <Footer />}
    </>
  );
};

export default MainLayout;
