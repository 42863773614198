import React, { useState } from "react";
import "../../styles/partners.scss";

import Charli from "../../assets/images/partners/charli.png";
import Danketsu from "../../assets/images/partners/danketsu.png";
import Gero from "../../assets/images/partners/gero.png";
import Hosky from "../../assets/images/partners/hosky.png";
import Pastel from "../../assets/images/partners/pastel.png";

import DiscordIcon from "../../assets/images/discord.png";
import TwitterIcon from "../../assets/images/twitter.png";
import WebsiteIcon from "../../assets/images/website.png";

const partnersData = [
  {
    title: "CHARLI3",
    twitter: "https://twitter.com/Oraclecharli3",
    discord: "https://discord.com/invite/Tnsc3HCA3A",
    website: "https://charli3.io/",
    img: Charli,
  },
  {
    title: "DANKETSU",
    twitter: "https://twitter.com/DanketsuNFT ",
    discord: "https://discord.com/invite/danketsu",
    website: "https://t.co/OWWjApyv9i",
    img: Danketsu,
  },
  {
    title: "GERO WALLET",
    twitter: "https://twitter.com/GeroWallet",
    discord: "https://discord.gg/Wj4EyjNdNb",
    website: "https://gerowallet.io/ ",
    img: Gero,
  },
  {
    title: "HOSKY",
    twitter: "https://twitter.com/hoskytoken",
    discord: "https://discord.com/invite/hosky",
    website: "https://hosky.io/",
    img: Hosky,
  },
  {
    title: "PASTEL LABZ",
    twitter: "https://twitter.com/PastelBudz",
    discord: "https://discord.gg/tz86CXwQ8g",
    website: "https://medium.com/pastel-labz",
    img: Pastel,
  },
];
const data = "";
const Partners = () => {
  return (
    <div className="partners">
      <div className="container">
        <div className="font-70">MD RACING PARTNERS</div>
        <br />
        <div className="content">
          {partnersData.map((item, index) => {
            return (
              <div className="partner-card">
                <div className = "image">
                  <img src={item.img} />
                </div>
            
                <div className="title">{item.title}</div>
                <div className="icons d-flex justify-content-center gap-2">
                  <div className="d-flex justify-content-center gap-3">
                    <a href={item.website} target="_blank">
                        <img className="icon" src={WebsiteIcon} />
                    </a>
                    <a href={item.twitter} target="_blank">
                        <img className="icon" src={TwitterIcon} />
                    </a>

                  </div>
                  <a href={item.discord} target="_blank">
                    <img className="icon" src={DiscordIcon} />
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Partners;
