import React, { useState, useEffect } from "react";

import "../../styles/mdLeaderboard.scss";
import Img1 from "../../assets/images/Baby Alien.png";
import Img2 from "../../assets/images/ThosAMAs.png";
import Img3 from "../../assets/images/Unbothered.png";
import Img4 from "../../assets/images/What the duck.png";
import Img5 from "../../assets/images/saltySeagulls.png";
import Img6 from "../../assets/images/PIXCARDZ.png";
import Img7 from "../../assets/images/Ape Nation.png";
import Img8 from "../../assets/images/APE RIOTING.png";
import Img9 from "../../assets/images/Bad Fox.png";
import Img10 from "../../assets/images/Blurry Kits Lounge.png";
import Img11 from "../../assets/images/Atlantik.png";
import Img12 from "../../assets/images/Jellycubes.png";
import Img13 from "../../assets/images/neis.png";
import Img13_2 from "../../assets/images/neis2.png";
import Img14 from "../../assets/images/safari.png";
import Img15 from "../../assets/images/Automatica.png";
import Img16 from "../../assets/images/Space pugs.png";
import Img17 from "../../assets/images/Lazy Llamas.png";
import Img18 from "../../assets/images/Mad Apes.png";
import Img19 from "../../assets/images/Critter Sweet Creations.png";
import Img20 from "../../assets/images/Degen Dino orb Society.png";
import Img21 from "../../assets/images/Lucky Lions.png";
import Img22 from "../../assets/images/Space APE club.png";
import Img23 from "../../assets/images/Fast Society.png";
import Img24 from "../../assets/images/Pastel Labz.png";
import Img25 from "../../assets/images/SYMC.png";
import Img26 from "../../assets/images/Knfty World.png";
import Img27 from "../../assets/images/TV Kills.png";
import Img28 from "../../assets/images/Acheron.png";
import Img29 from "../../assets/images/Zeitaku.png";

import parse from "html-react-parser";
import axios from "axios";

import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import usePreventBodyScroll from "../../hooks/usePreventBodyScroll";
import MintModal from "../../components/MintModal";
const leaderboardData = [
  {
    name: "Baby Alien Club",
    imgUrl: Img1,
    totalRefferal: 0,
    referral_info:
      "addr1qxkuepaamyrsvr9f7dyyvpkvnefgpy93wf5v75qjymecnvunqge79ep0launsvvfhelgq4jjv0quk9dgtxdn0d3udfhs79h3cl",
    referralLink:
      "https://maddogcarclub.ada-anvil.io/en/?r=addr1qxkuepaamyrsvr9f7dyyvpkvnefgpy93wf5v75qjymecnvunqge79ep0launsvvfhelgq4jjv0quk9dgtxdn0d3udfhs79h3cl",
  },
  {
    name: "ThoseAMAs",
    imgUrl: Img2,
    totalRefferal: 0,
    referral_info: "$wag",
    referralLink: "https://maddogcarclub.ada-anvil.io/en/?r=$wag",
  },
  {
    name: "Unbothered <br /> Wolves",
    imgUrl: Img3,
    totalRefferal: 0,
    referral_info: "$unbotheredwolves",
    referralLink:
      "https://maddogcarclub.ada-anvil.io/en/?r=addr1qxr380p62qu8we5naey3c7exqdh4fuvf4eyu4vlwqyc6769w4zm8k647ycvqxl5cecvhq30tl26dxx8sey5jd5nfz8ds6sf5g4",
  },
  {
    name: "WHAT THE <br /> DUCK",
    imgUrl: Img4,
    totalRefferal: 0,
    referral_info:
      "addr1q89d0qsq80mgp4e5kkj0gxrjrgn90rzxvc4k5affvr6tasu0ea0dkh4gz825yw0rnwg7c2u53z0e0vrnsdyylsk4r0aqtvnrfp",
    referralLink:
      "https://maddogcarclub.ada-anvil.io/en/?r=addr1q89d0qsq80mgp4e5kkj0gxrjrgn90rzxvc4k5affvr6tasu0ea0dkh4gz825yw0rnwg7c2u53z0e0vrnsdyylsk4r0aqtvnrfp",
  },
  {
    name: "Salty Seagulls <br /> Society",
    imgUrl: Img5,
    totalRefferal: 0,
    referral_info: "$saltyseagulls",
    referralLink: "https://maddogcarclub.ada-anvil.io/en/?r=$saltyseagulls",
  },
  {
    name: "PIXCARDZ",
    imgUrl: Img6,
    totalRefferal: 0,
    referral_info: "$pixcardz",
    referralLink: "https://maddogcarclub.ada-anvil.io/en/?r=$pixcardz",
  },
  {
    name: "Ape Nation",
    imgUrl: Img7,
    totalRefferal: 0,
    referral_info: "$apecommunity",
    referralLink: "https://maddogcarclub.ada-anvil.io/en/?r=$apecommunity",
  },
  {
    name: "APING RIOT<br/> COMMUNITY",
    imgUrl: Img8,
    totalRefferal: 0,
    referral_info: "$riot-raffle",
    referralLink: "https://maddogcarclub.ada-anvil.io/en/?r=$riot-raffle",
  },
  {
    name: "Bad Fox <br/> Mortorcycle Club",
    imgUrl: Img9,
    totalRefferal: 0,
    referral_info: "$badfoxmc",
    referralLink: "https://maddogcarclub.ada-anvil.io/en/?r=$badfoxmc",
  },
  {
    name: "Blurry Kits Lounge",
    imgUrl: Img10,
    totalRefferal: 0,
    referral_info: "$blurrykits",
    referralLink: "https://maddogcarclub.ada-anvil.io/en/?r=$blurrykits",
  },
  {
    name: "Atlanick Ocean",
    imgUrl: Img11,
    totalRefferal: 0,
    referral_info: "$atlanick",
    referralLink: "https://maddogcarclub.ada-anvil.io/en/?r=$atlanick",
  },
  {
    name: "Jellycubes - BIG",
    imgUrl: Img12,
    totalRefferal: 0,
    referral_info:
      "addr1qy8aaq7gxxpf9tchzt0s948jxp75quvfmsqvcuvxz0eahxt5pmssn6xyrllus6429gcyy8h85ghwxtdwd9p63cla20zs7hk245",
    referralLink:
      "https://maddogcarclub.ada-anvil.io/en/?r=addr1qy8aaq7gxxpf9tchzt0s948jxp75quvfmsqvcuvxz0eahxt5pmssn6xyrllus6429gcyy8h85ghwxtdwd9p63cla20zs7hk245",
  },
  {
    name: "",
    imgUrl: Img13,
    totalRefferal: 0,
    referral_info: "$Awesomeisjayell",
    referralLink: "https://maddogcarclub.ada-anvil.io/en/?r=$Awesomeisjayell",
  },
  {
    name: "SAFARI SQUAD",
    imgUrl: Img14,
    totalRefferal: 0,
    referral_info:
      "addr1q9q9r7fxztrv2jxdk0amdhfes0rpwv4n8k4yr2lcuh2yn878f86wefnpssj297y30vwpue6ygf9fze227yrkyfykeu8qwewfz8",
    referralLink:
      "https://maddogcarclub.ada-anvil.io/en/?r=addr1q9q9r7fxztrv2jxdk0amdhfes0rpwv4n8k4yr2lcuh2yn878f86wefnpssj297y30vwpue6ygf9fze227yrkyfykeu8qwewfz8",
  },
  // {
  //   name: "Automatica",
  //   imgUrl: Img15,
  //   totalRefferal: 0,
  //   referral_info:
  //     "addr1q94un2d8qtukwqhj98jjtvyej44y5ndp2a5t7mgyxq9n2jf02v9g08u6fwtqxgfpkvucwn8js8t248yrwpclalsr8n4s4z6y8r",
  //   referralLink:
  //     "https://maddogcarclub.ada-anvil.io/en/?r=addr1q94un2d8qtukwqhj98jjtvyej44y5ndp2a5t7mgyxq9n2jf02v9g08u6fwtqxgfpkvucwn8js8t248yrwpclalsr8n4s4z6y8r",
  // },
  {
    name: "Space Pugs Alpha",
    imgUrl: Img16,
    totalRefferal: 0,
    referral_info: "$adamj",
    referralLink: "https://maddogcarclub.ada-anvil.io/en/?r=$adamj",
  },
  {
    name: "Lazy Llamas",
    imgUrl: Img17,
    totalRefferal: 0,
    referral_info: "$lazyllamas",
    referralLink: "https://maddogcarclub.ada-anvil.io/en/?r=$lazyllamas",
  },
  {
    name: "Mad Ⓥ-Apes ",
    imgUrl: Img18,
    totalRefferal: 0,
    referral_info:
      "addr1q9ckuprxf59e0vkqle4ksnrajpg505rx9pskv6c2j72zath7v7wr6c23ywzaygt5dfhchvlxjxn65f9mxjxh0ptpputswxm6w0",
    referralLink:
      "https://maddogcarclub.ada-anvil.io/en/?r=addr1q9ckuprxf59e0vkqle4ksnrajpg505rx9pskv6c2j72zath7v7wr6c23ywzaygt5dfhchvlxjxn65f9mxjxh0ptpputswxm6w0",
  },
  {
    name: "Critter Sweet Creations ",
    imgUrl: Img19,
    totalRefferal: 0,
    referral_info: "$crittersweet",
    referralLink: "https://maddogcarclub.ada-anvil.io/en/?r=$crittersweet",
  },
  {
    name: "Degen Dino orb Society",
    imgUrl: Img20,
    totalRefferal: 0,
    referral_info: "$degendino",
    referralLink: "https://maddogcarclub.ada-anvil.io/en/?r=$degendino",
  },
  {
    name: "Lucky Lions",
    imgUrl: Img21,
    totalRefferal: 0,
    referral_info:
      "addr1x9w92a0yh5z68t8tz9snxs6u5e6eglzss9xxgv39rr8w7p2u2467f0g95wkwkytpxdp4efn4j379pq2vvsez2xxwauzsuqgdhd",
    referralLink:
      "https://maddogcarclub.ada-anvil.io/en/?r=addr1x9w92a0yh5z68t8tz9snxs6u5e6eglzss9xxgv39rr8w7p2u2467f0g95wkwkytpxdp4efn4j379pq2vvsez2xxwauzsuqgdhd",
  },
  {
    name: "Space Ape Club",
    imgUrl: Img22,
    totalRefferal: 0,
    referral_info:
      "addr1q84fpuye5x98qh423fc5sg5nwx6zg3qrll6d9kr5kz47g249e23557mnyl44zshd4k6hx5plt867c9lt5ehcz85rlaxsqxtsrd",
    referralLink:
      "https://maddogcarclub.ada-anvil.io/en/?r=addr1q84fpuye5x98qh423fc5sg5nwx6zg3qrll6d9kr5kz47g249e23557mnyl44zshd4k6hx5plt867c9lt5ehcz85rlaxsqxtsrd",
  },
  {
    name: "Fast Society",
    imgUrl: Img23,
    totalRefferal: 0,
    referral_info:
      "addr1q8pxzm32qr2uatfyyn5f3mgud2wpy2yxr4ym6hya0r0r5rk7g9p9myr4j96we4qpuy2a4gkwhe6utslvzulq4mr5ne6qg5ya3z",
    referralLink:
      "https://maddogcarclub.ada-anvil.io/en/?r=addr1q8pxzm32qr2uatfyyn5f3mgud2wpy2yxr4ym6hya0r0r5rk7g9p9myr4j96we4qpuy2a4gkwhe6utslvzulq4mr5ne6qg5ya3z",
  },
  {
    name: "Pastel Labz",
    imgUrl: Img24,
    totalRefferal: 0,
    referral_info: "$pastellabz",
    referralLink: "https://maddogcarclub.ada-anvil.io/en/?r=$pastellabz",
  },
  {
    name: "Smooth Yeti Mountain Club",
    imgUrl: Img25,
    totalRefferal: 0,
    referral_info: "$moothyeti",
    referralLink: "https://maddogcarclub.ada-anvil.io/en/?r=$moothyetiz",
  },
  {
    name: "Knfty World Creatures",
    imgUrl: Img26,
    totalRefferal: 0,
    referral_info:
      "addr1qxs2mu7hram7autzpxrjdl5hywjmnx62fe4z5xuqqyrxpxp2f4hvnq4vthlmlg66cs9lgkkcf635s4u83lgg64uymhrsmhetg3",
    referralLink:
      "https://maddogcarclub.ada-anvil.io/en/?=addr1qxs2mu7hram7autzpxrjdl5hywjmnx62fe4z5xuqqyrxpxp2f4hvnq4vthlmlg66cs9lgkkcf635s4u83lgg64uymhrsmhetg3",
  },
  {
    name: "TV Kills",
    imgUrl: Img27,
    totalRefferal: 0,
    referral_info:
      "addr1qxwchvyexuv9ph90ct2u236afcsxwa85ux6u9552q8etfj2lht4xgqfvqxkfw6vfcthrfng7nwfp6pjklq8rdd83hjlshv4q8p",
    referralLink:
      "https://maddogcarclub.ada-anvil.io/en/?=addr1qxwchvyexuv9ph90ct2u236afcsxwa85ux6u9552q8etfj2lht4xgqfvqxkfw6vfcthrfng7nwfp6pjklq8rdd83hjlshv4q8p",
  },
  // {
  //   name: "AlienzSociety",
  //   imgUrl: Img27,
  //   totalRefferal: 0,
  //   referral_info:
  //     "addr1qxwchvyexuv9ph90ct2u236afcsxwa85ux6u9552q8etfj2lht4xgqfvqxkfw6vfcthrfng7nwfp6pjklq8rdd83hjlshv4q8p",
  //   referralLink:
  //     "https://maddogcarclub.ada-anvil.io/en/?=addr1qykacwdncnqfw4j9xlny2d4kqq42vnsjznce6qzgvhdu6l39ef7syptu5qm7hvhmu2kfl29rejqssygt7fk8yrgx8flqdx9zk2s://maddogcarclub.ada-anvil.io/en/?=addr1qxwchvyexuv9ph90ct2u236afcsxwa85ux6u9552q8etfj2lht4xgqfvqxkfw6vfcthrfng7nwfp6pjklq8rdd83hjlshv4q8p",
  // },
  {
    name: "The Acheron Guards",
    imgUrl: Img28,
    totalRefferal: 0,
    referral_info: "$thechest",
    referralLink: "https://maddogcarclub.ada-anvil.io/en/?=$thechest",
  },
  {
    name: "Zeitaku Dragonz",
    imgUrl: Img29,
    totalRefferal: 0,
    referral_info:
      "addr1q9300mvfy88qa593f2uekpela7am2lj8r2xrzqnw97zrq729ae5u5vhyyxtvyd2muk9fefquuu8vy98jqzqd7u3duxjs9xk02s",
    referralLink:
      "https://maddogcarclub.ada-anvil.io/en/?=addr1q9300mvfy88qa593f2uekpela7am2lj8r2xrzqnw97zrq729ae5u5vhyyxtvyd2muk9fefquuu8vy98jqzqd7u3duxjs9xk02s",
  },
];

const MDLeaderboard = () => {
  const { disableScroll, enableScroll } = usePreventBodyScroll();
  const [lbData, setLBData] = useState(leaderboardData);
  const [referrals, setRefferals] = useState([]);
  const [secondIndex, setSecondIndex] = useState(0);

  const sortArray = (a, b) => {
    return b.referral - a.referral;
  };
  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        Number(a[property]) > Number(b[property])
          ? -1
          : Number(a[property]) < Number(b[property])
          ? 1
          : 0;
      return result * sortOrder;
    };
  }
  const getReferrals = async () => {
    const { data } = await axios.get(
      "https://us-central1-anvil-6fe83.cloudfunctions.net/referralAdmin?policyId=12a0bad7a63e1fb043159b583215900859f75ab627cdc0428fe6ae72"
    );
    let referrals_list = data.referrals.filter((item) => {
      return (
        item.referral.search("MDRacer") < 0 &&
        item.referral !==
          "addr1q94un2d8qtukwqhj98jjtvyej44y5ndp2a5t7mgyxq9n2jf02v9g08u6fwtqxgfpkvucwn8js8t248yrwpclalsr8n4s4z6y8r"
      );
    });
    console.log("referral length", referrals_list);
    if (referrals_list.length > 0) {
      setSecondIndex(referrals_list.length);

      const arr = referrals_list.sort((a, b) => +b.assets - +a.assets);
      setRefferals([...arr]);
      setLBData((current) =>
        current.filter((obj) => {
          let testIndex = referrals.findIndex(
            (ele) => ele.referral == obj.referral_info
          );
          return testIndex < 0;
        })
      );
    }
  };

  useEffect(() => {
    getReferrals();
  }, []);
  return (
    <div className="md-leaderboard">
      <section className="about-section">
        <div className="d-flex flex-column">
          <div className="d-flex  align-items-center md-racing-logo">
            <div className="leaderboard-title">
              MD RACER MINT PARTNER <br />
              LEADERBOARD{" "}
            </div>
          </div>
          <div className="img-panel justify-content-center align-items-center">
            <div onMouseEnter={disableScroll} onMouseLeave={enableScroll}>
              <ScrollMenu
                Header={<div></div>}
                Footer={<div></div>}
                onWheel={onWheel}
              >
                {leaderboardData.map((item, index) => {
                  let imgUrl = index == 12 ? Img13_2 : item.imgUrl;
                  return <img key={index} src={imgUrl} />;
                })}
              </ScrollMenu>
            </div>
          </div>
        </div>
      </section>
      <section className="leaderboard-section">
        <div className="container d-flex flex-column">
          <div className="text-panel">
            <div className="font-title font-bold">LEADERBOARD</div>
            <div className="font-subtitle font-bold">
              <p>
                First place will receive a premium sponsorship package in MD
                Racing!
              </p>
              <p>
                Visit their Discord for THEIR minting link to support your
                communities to reach first place.
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-center gap-3 flex-wrap">
            <div className="prize-panel">
              <div>PHASE 2 PRIZES</div>
              <p>1st Place: 1,500 ADA Floor Sweep + 15 Racers + Staking</p>
              <p>2nd Place: 1,000 ADA Floor Sweep + 10 MD Racers</p>
              <p>3rd Place: 500 ADA Floor Sweep + 5 MD Racers</p>
            </div>
            <div className="prize-panel">
              <div>MILESTONE PRIZES</div>
              <p>75 MD Racers: PFP Utilization in MD Racing & MD Royale</p>
              <p>100 MD Racers: Decal Sponsorship Package</p>
              <p>150 MD Racers: Exclusive Weapon Skin </p>
              <p>250 MD Racers: Race Track Sponsorship </p>
            </div>
          </div>

          {referrals.map((item, index) => {
            const data = leaderboardData.find(
              (ele) => ele.referral_info === item.referral
            );
            if (data) {
              return (
                <div key={index}>
                  <hr />
                  <div className="leaderboard-item">
                    <button
                      className={index === 0 ? "number active" : "number"}
                    >
                      {index + 1}
                    </button>

                    <div className="icon">
                      {data.name == "" ? (
                        <img src={data.imgUrl} className="special" />
                      ) : (
                        <>
                          <img src={data.imgUrl} />
                          <p>
                            <a href={data.referralLink} target="_blank">
                              {parse(data.name)}
                            </a>
                          </p>
                        </>
                      )}
                    </div>

                    <div className="referal">
                      Total Referrals
                      <p>{item.assets}</p>
                    </div>
                  </div>
                </div>
              );
            }
          })}
          {leaderboardData
            .filter(
              (item) =>
                !referrals.find(
                  (refferal) => refferal.referral === item.referral_info
                )
            )
            .map((item, index) => (
              <div key={index}>
                <hr />
                <div className="leaderboard-item">
                  <button className="number">
                    {referrals.length + 1 + index}
                  </button>

                  <div className="icon">
                    {item.name == "" ? (
                      <img src={item.imgUrl} className="special" />
                    ) : (
                      <>
                        <img src={item.imgUrl} />
                        <p>
                          <a href={item.referralLink} target="_blank">
                            {parse(item.name)}
                          </a>
                        </p>
                      </>
                    )}
                  </div>

                  <div className="referal">
                    Total Referrals
                    <p>{item.totalRefferal}</p>
                  </div>
                </div>
              </div>
            ))}

          {/* {referrals &&
            referrals.length > 0 &&
            referrals.map((item, index) => {
              console.log("referrals.length", referrals.length);

              let temp_index = referral_list.indexOf(item.referral);
              console.log("temp_index", temp_index);

              if (temp_index < 0) {
                console.log("index =>>>>", index);
                console.log("itemiterral", item.referral);
              } else {
                return (
                  <div key={index}>
                    <hr />
                    <div className="leaderboard-item">
                      <button
                        className={index === 0 ? "number active" : "number"}
                      >
                        {index + 1}
                      </button>

                      <div className="icon">
                        {leaderboardData[temp_index].name == "" ? (
                          <img
                            src={leaderboardData[temp_index].imgUrl}
                            className="special"
                          />
                        ) : (
                          <>
                            <img src={leaderboardData[temp_index].imgUrl} />
                            <p>
                              <a
                                href={leaderboardData[temp_index].referralLink}
                                target="_blank"
                              >
                                {parse(leaderboardData[temp_index].name)}
                              </a>
                            </p>
                          </>
                        )}
                      </div>

                      <div className="referal">
                        Total Referrals
                        <p>{item.assets}</p>
                      </div>
                    </div>
                  </div>
                );
              }
            })} */}

          {/* {lbData &&
            lbData.length > 0 &&
            lbData.map((item, index) => {
              let temp_index = referrals.length + 1;
              let index2 = referrals.findIndex(
                (ele) => ele.referral == item.referral_info
              );
              console.log("index2", index2);
              if (index2 < 0) {
                return (
                  <div key={index}>
                    <hr />
                    <div className="leaderboard-item">
                      <button className="number">{temp_index + index}</button>

                      <div className="icon">
                        {item.name == "" ? (
                          <img src={item.imgUrl} className="special" />
                        ) : (
                          <>
                            <img src={item.imgUrl} />
                            <p>
                              <a href={item.referralLink} target="_blank">
                                {parse(item.name)}
                              </a>
                            </p>
                          </>
                        )}
                      </div>

                      <div className="referal">
                        Total Referrals
                        <p>0</p>
                      </div>
                    </div>
                  </div>
                );
              }
            })} */}
        </div>
      </section>
    </div>
  );
};

function onWheel(apiObj, ev) {
  const isThouchpad = Math.abs(ev.deltaX) != 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

export default MDLeaderboard;
