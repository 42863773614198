import React, { lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainLayout from "./layouts/mainLayout";
import BugReport from "./pages/MDRacing/BugReport";
import PastGame from "./pages/MDRacing/PastGame";
import MadDogsRanking from "../src/pages/MadDogs/MadDogsRanking";
import MDToken from "./pages/MDToken";
import LandingPage from "./pages/LandingPage";

import MDLicense from "./pages/MDLicense/MDLicense";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import MDRacing from "./pages/MDRacing/MDRacing";

import MadDogs from "./pages/MadDogs/MadDogs";

import MDRacers from "./pages/MDRacers/MDRacers";
import MDRacersWhitePaper from "./pages/MDRacers/MDRacersWhitePaper";
//import MDRacersRanking from "./pages/MDRacers/MDRacersRanking";

import MadDogCarsWhitePaper from "./pages/MadDogCars/MadDogCarsWhitePaper";
import MadDogsWhitePaper from "./pages/MadDogs/MadDogsWhitePaper";
import MDRacingWhitepaper from "./pages/MDRacing/MDRacingWhitepaper";
import MDLicenseWhitepaper from "./pages/MDLicense/MDLicenseWhitepaper";
import MadDogCars from "./pages/MadDogCars/MadDogCars";
// import CheckAllocation from "./pages/MDRacers/CheckAllocation";
import MDLeaderboard from "./pages/MDRacers/MDLeaderboard";
import Partners from "./pages/MDRacing/Partners";
// import MintProcess from "./pages/MDRacers/MintProcess";
// const LandingPage = lazy(() => import("./pages/LandingPage"));
// const MDLicense = lazy(() => import("./pages/MDLicense/MDLicense"));
// const Privacy = lazy(() => import("./pages/Privacy"));
// const Terms = lazy(() => import("./pages/Terms"));
// const MDRacing = lazy(() => import("./pages/MDRacing/MDRacing"));
// const MadDogsRanking = lazy(() => import("./pages/MadDogs/MadDogsRanking"));
// const MadDogs = lazy(() => import("./pages/MadDogs/MadDogs"));
// const MDRacers = lazy(() => import("./pages/MDRacers/MDRacers"));
// const MadDogCarsWhitePaper = lazy(() =>
//   import("./pages/MadDogCars/MadDogCarsWhitePaper")
// );
// const MadDogsWhitePaper = lazy(() =>
//   import("./pages/MadDogs/MadDogsWhitePaper")
// );
// const MDRacingWhitepaper = lazy(() =>
//   import("./pages/MDRacing/MDRacingWhitepaper")
// );
// const MDLicenseWhitepaper = lazy(() =>
//   import("./pages/MDLicense/MDLicenseWhitepaper")
// );
// const MadDogCars = lazy(() => import("./pages/MadDogCars/MadDogCars"));
// const CheckAllocation = lazy(() => import("./pages/MDRacers/CheckAllocation"));
// const MDLeaderboard = lazy(() => import("./pages/MDRacers/MDLeaderboard"));
// const MintProcess = lazy(() => import("./pages/MDRacers/MintProcess"));
// const MDRacersWhitePaper = lazy(() =>
//   import("./pages/MDRacers/MDRacersWhitePaper")
// );
const MDRacersRanking = lazy(() => import("./pages/MDRacers/MDRacersRanking"));

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path="/" element={<LandingPage />} />
          <Route path="/mad-dogs" element={<MadDogs />} />
          <Route path="/mad-dogs-whitepaper" element={<MadDogsWhitePaper />} />
          <Route path="/mad-dogs-ranking" element={<MadDogsRanking />} />

          <Route path="/mad-dog-cars" element={<MadDogCars />} />
          <Route
            path="/mad-dog-cars-whitepaper"
            element={<MadDogCarsWhitePaper />}
          />

          <Route path="/md-racers" element={<MDRacers />} />
          <Route
            path="/md-racers-whitepaper"
            element={<MDRacersWhitePaper />}
          />
          <Route path="/md-racers-ranking" element={<MDRacersRanking />} />

          <Route path="/md-license" element={<MDLicense />} />
          <Route
            path="/md-license-whitepaper"
            element={<MDLicenseWhitepaper />}
          />

          <Route path="/md-racing" element={<MDRacing />} />
          <Route
            path="/md-racing-whitepaper"
            element={<MDRacingWhitepaper />}
          />
          <Route path="/md-racing-bugreport" element={<BugReport />} />
          <Route path="/md-racing-pastgame" element={<PastGame />} />
          <Route path="/md-racing-partners" element={<Partners />} />

          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          {/* <Route path="/check-allocation" element={<CheckAllocation />} />
          <Route path="/mint-process" element={<MintProcess />} /> */}
          <Route path="/md-leaderboard" element={<MDLeaderboard />} />

          <Route path="/md-token" element={<MDToken />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
