import React, { useState } from "react";
import "../../styles/whitepaper.scss";
const MadDogsWhitePaper = () => {
  return (
    <div className="whitepaper">
      <div className="container">
        <div className="title">
          <div className="font-70">
            MAD DOGS<div className="font-30">WHITEPAPER</div>
          </div>
        </div>
        <div className="content">
          <div className="font-18">
            As we continue throughout our Roadmap, our Whitepaper will be
            updated. Any and all information on this page are subject to review,
            and nothing in this paper should be considered financial advice or
            used to inform financial or investment decisions. Mad Dog Car Club
            is not responsible for, or liable for any damages of any kind
            arising from the use of any information. Please make sure to always
            do your own research.
            <br />
            <br />
            This page has last been updated: 12/22/23
          </div>
          <div className="font-24">
            Mad Dogs was the first-ever collection Mad Dog Car Club (MDCC) has
            released. This groundbreaking collection brought together dog lovers
            and car enthusiasts. Mad Dogs was released in April 2022 with a
            supply of 9,999 algorithmically generated assets. Our focus with our
            first launch was to show the community that we are not just an
            ordinary brand but rather a brand that will immerse itself in both
            the physical and digital world. The utilization of Mad Dogs includes
            top-of-line benefits in the cNFT space.
          </div>
          <div className="font-30">
            WHAT DO MAD DOGS DO? <br />
            <br />
            <div className="font-24 do-list">
              <div>
                <li>
                  Non-Custodial Staking MD Token.io and other staking platforms*
                </li>
                <li>50% of Monthly royalties to all top 1k holders</li>
                <li>PFP Utilization</li>
                <li>Benefits for all future Whitelists</li>
                <li>Access to our blockchain p2e racing game, MD Racing.</li>
              </div>
            </div>
          </div>

          <div className="font-24">
            This was our introduction to the cNFT space, and we came in with a
            boom. The sold-out collection allowed the MD Team to represent the
            vision behind the company. The roadmap clearly showed our goals and
            promises. All in which we have accomplished. Furthermore, this
            collection represented a glimpse of our future project MD Racing.
            Our now-developed blockchain play-to-earn racing game has a strict
            goal of revolutionizing game-fi and leading as a role model to other
            blockchain games.
          </div>
        </div>
      </div>
    </div>
  );
};

export default MadDogsWhitePaper;
