import React, { useEffect, useState } from "react";
import "../../styles/madDogs.scss";
import MdRacers from "../../assets/images/Md-racers.png";
import RoadMap from "../../assets/images/mad-dog-roadmap.png";
import RoadMapMobile from "../../assets/images/2AMClub_Mobile_Roadmap.png";
import Slick from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMedia } from "react-use";
import { Link } from "react-router-dom";
import axios from "axios";
import numeral from "numeral";
import MintModal from "../../components/MintModal";

const saleTexts = [
  {
    name: "Items",
    value: "9,999",
  },
  {
    name: "Owners",
    value: "1750",
  },
  {
    name: "Floor",
    value: "70",
  },
  {
    name: "Volume",
    value: "3.09m",
  },
];

const faqData = [
  {
    title: "when will MD racers mint?",
    text: "The MD Racers will be minted on the Cardano Blockchain on December 13th, 2022. ",
  },
  {
    title: "What was the mint price?",
    text: "For Whitelist, the price is 60 ADA. For Public, the price is 75 ADA.",
  },
  {
    title: "What is the total supply of md racers",
    text: "Total Supply is 9,999 MD Racers on the Cardano Blockchain.",
  },
  {
    title: "What is the main utility of MD racers?",
    text: "MD Racer will be your in game identity in MD Racing, you will be able to stake MD Racers for MD Tokens, receive quarterly airdropped MD Modification packages, access to other metaverses, and fully assembled car airdrops  from every future car collection for every 5 Racers held.",
  },
];

const utilityData = [
  "Non-Custodial Staking on MDToken.io",
  "Every 5 Racers that you hold you will be airdropped a fully assembled car from every future car collection.",
  "Every 3 months after the release of MD Racers each asset owner will be airdropped a MD modification pack which include exclusive car-skins, and other future car accessories.",
  "Access to other metaverses",
  "Access to MD Racing",
];

const policyID = "12a0bad7a63e1fb043159b583215900859f75ab627cdc0428fe6ae72";

const settings = {
  className: "center",
  centerMode: true,
  dots: false,
  infinite: true,
  centerPadding: "0px",
  speed: 500,
  slidesToShow: 3,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        centerPadding: "60px",
        slidesToShow: 2,
        autoplay: true,
        autoplaySpeed: 2000,
      },
    },
    {
      breakpoint: 550,
      settings: {
        centerPadding: "120px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 450,
      settings: {
        centerPadding: "80px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 320,
      settings: {
        centerPadding: "60px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 300,
      settings: {
        centerPadding: "20px",
        slidesToShow: 1,
      },
    },
  ],
};

const MDRacers = () => {
  const isMobile = useMedia("(max-width: 768px)");
  const [countTime, setCountTime] = useState("00");
  const [saleData, setSaleData] = useState(saleTexts);
  const [highestSale, setHighestSale] = useState(0);
  // const [showMintModal, setShowMintModal] = useState(true);
  const [assetsData, setAssetsData] = useState([]);
  const getPolicyData = async () => {
    const { data } = await axios.get(
      "https://api.opencnft.io/1/policy/" + policyID
    );
    saleData[0].value = data.asset_minted;
    saleData[1].value = data.asset_holders;
    saleData[2].value = data.floor_price / 1000000;
    saleData[3].value = data.total_volume / 1000000;
    setHighestSale(data.highest_sale.price / 1000000);
    //console.log(data);
    setSaleData([...saleData]);
  };
  const getPolicyTransations = async () => {
    const { data } = await axios.get(
      "https://api.opencnft.io/1/policy/" + policyID + "/transactions"
    );
    setAssetsData(data.items);
  };

  useEffect(() => {
    const now = new Date().toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
    });
    const endTime = new Date("12/13/2022 04:00 pm").toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
    });
    const diffTime =
      (new Date(endTime).getTime() - new Date(now).getTime()) /
      (1000 * 3600 * 24);
    setCountTime("" + Math.ceil(diffTime));
    getPolicyData();
    getPolicyTransations();
  }, []);

  return (
    <div className="mad-racers">
      <section className="about-section">
        <div className="container d-flex">
          <div className="d-flex align-items-end centario-avatar">
            <img src={MdRacers} alt="md-racers-avatar" />
          </div>
          <div className="text-panel d-flex flex-column justify-content-center">
            <h1 className="font-title font-bold">MD RACERS</h1>
            <h4 className="font-h4 font-medium">
              Introducing MD Racers, our upcoming release under MDCC. These MD
              Racers will represent your identity in our Car Club. The
              collection consists of 9,999 algorithmically generated racers on
              the Cardano Blockchain.
            </h4>
            <h5 className="font-h5 font-light">
              Each MD Racer has their own unique personality. The utilization of
              these Racers will be implemented in MD Racing, and our future
              Metaverse, MD society. There are many other benefits you obtain by
              holding one or many of these Racers. The mint will be a 7 Days
              reveal after the Whitelist Sale.
            </h5>
            <div className="btn-panel d-flex align-items-center">
              {/* <button className="btn font-nav font-bold d-flex align-items-center justify-content-center">
                MINT NOW
              </button> */}
              {/* <button 
                className="btn font-nav font-bold d-flex align-items-center justify-content-center">CHECK ALLOCATION
              </button> */}
              {/* <Link
                to="/md-racers-whitepaper"
                className="btn font-nav font-bold d-flex align-items-center justify-content-center"
              >
                WHITEPAPER
              </Link> */}
            </div>
            <div className="btn-panel d-flex align-items-center">
              <Link
                to="/md-racers-ranking"
                className="btn font-nav font-bold d-flex align-items-center justify-content-center"
              >
                RANKING
              </Link>
              <Link
                to="/md-racers-whitepaper"
                className="btn font-nav font-bold d-flex align-items-center justify-content-center"
              >
                WHITEPAPER
              </Link>
              {/* <button
                className="btn font-nav font-bold d-flex align-items-center justify-content-center">LEADERBOARD
              </button>
              <button
                className="btn font-nav font-bold d-flex align-items-center justify-content-center">MINT PROCESS
              </button> */}
            </div>
          </div>
        </div>
      </section>
      <section className="sales-section">
        <div className="container d-flex flex-column align-items-center">
          <h1 className="font-h1 font-bold">LATEST SALES</h1>
          <div className="sale-text-panel d-flex">
            {saleData.map((saleText, index) => {
              let value = saleText.value;
              if (String(value).length > 4) {
                value = numeral(value).format("0.0 a");
              }
              return (
                <div className="sale-text-item text-center" key={index}>
                  <p className="sale-text_value font-normal">{value}</p>
                  <p className="sale-text_label font-light">{saleText.name}</p>
                </div>
              );
            })}
          </div>
          <a
            target="_blank"
            href="https://www.jpg.store/collection/maddogracers"
            className="visit-btn font-button2 font-bold"
            rel="noreferrer"
          >
            Visit JPG.STORE
          </a>
        </div>
        <div className="slick-container container">
          <Slick {...settings}>
            {assetsData.map((item, index) => {
              const imageId = item?.thumbnail.thumbnail.replaceAll(
                "ipfs://",
                ""
              );
              let seconds = (+new Date() - item?.sold_at * 1000) / 1000;
              let time = "";
              if (seconds / 3600 < 1) {
                time =
                  time +
                  ((seconds % 3600) / 60).toFixed() +
                  (((seconds % 3600) / 60).toFixed() ? " minutes" : " minute");
              } else {
                if (seconds / 3600 > 23) {
                  time =
                    (seconds / 3600 / 24).toFixed() +
                    ((seconds / 3600 / 24).toFixed() > 1 ? " days " : " day ");
                }
                time =
                  time +
                  ((seconds % (3600 * 24)) / 3600).toFixed() +
                  (((seconds % (3600 * 24)) / 3600).toFixed() > 1
                    ? " hours "
                    : " hour ");
                time =
                  time +
                  (((seconds % (3600 * 24)) % 3600) / 60).toFixed() +
                  " minutes";
              }
              return (
                <div
                  className="slick-item d-flex flex-column align-items-center"
                  key={index}
                >
                  <div className="img-panel">
                    <img
                      src={`https://image-optimizer.jpgstoreapis.com/${imageId}/?width=600`}
                      alt="dog-avatar"
                    />
                  </div>
                  <p className="slick-text font-light text-center">
                    Sold {time} ago
                  </p>
                </div>
              );
            })}
          </Slick>
        </div>
        <div className="utility-panel">
          <div className="container d-flex">
            <div className="utility-text-panel w-50">
              <h1 className="font-title font-bold">UTILITY</h1>
              <ul>
                {utilityData.map((text, index) => (
                  <li key={index} className="font-h5 font-light">
                    {text}
                  </li>
                ))}
              </ul>
            </div>
            <div className="utility-item-panel w-50 d-flex flex-column align-items-end">
              <div className="utility-item font-medium">
                Highest Sale: {highestSale} ADA
              </div>
              <div className="utility-item font-medium">WL Mint: 60 ADA</div>
              <div className="utility-item font-medium">
                Public Mint: 75 ADA
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="road-map-section">
        <div className="container">
          <img src={isMobile ? RoadMapMobile : RoadMap} alt="roadmap" />
        </div>
      </section>
      <section className="faq-section">
        <div className="container">
          <h1 className="font-h1 font-bold text-center">
            FREQUENTLY ASKED QUESTIONS
          </h1>
          <div className="faq-text-panel">
            {faqData.map((faqItem, index) => (
              <div className="faq-item" key={index}>
                <h3 className="font-button1 font-bold">{faqItem.title}</h3>
                <p className="font-nav font-light">{faqItem.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <MintModal show={showMintModal} onClose={() => setShowMintModal(false)} /> */}
    </div>
  );
};

export default MDRacers;
