import React, { useState } from "react";
import "../../styles/madDogs.scss";
import MdRacingImg from "../../assets/images/MDCC_RacingLogo.png";
import GameModeImg from "../../assets/images/Game-mode_img.png";
import EllipseImg1 from "../../assets/images/Ellipse 1.png";
import EllipseImg2 from "../../assets/images/Ellipse 2.png";
import EllipseImg3 from "../../assets/images/Ellipse 3.png";
import GarageImg from "../../assets/images/MDCC_Garage.png";
import StarImg from "../../assets/images/Star.png";
import { Link } from "react-router-dom";
import MintModal from "../../components/MintModal";

const gameModesText = [
  {
    title: "PRACTICE",
    text: "This game mode will allow users to play solo and build their skills when it comes to racing. Users will be able to get familiar with the cars they own and the tracks that they race on.",
  },
  {
    title: "UNRANKED",
    text: "This multiplayer game mode will be for users to play against others for fun and earn achievements for their MD Racers profiles. Unranked mode will include 4, 6, and 8 player races.",
  },
  {
    title: "RANKED",
    text: "This multiplayer game mode will be for the skilled users who want to earn big. Players will be able to use their MD Tokens to stake into races against other players. There will be different tiers depending on the skill of the player. We will be having a monthly leaderboard which will display the players who have won the most races in ranked mode (top players for the month will be rewarded). Ranked mode will include 4, 6, and 8 player races.",
  },
];
const garageText = [
  {
    title: "CATEGORY 1: MAD DOG",
    text: "Mad Dog Category will display all of your Mad Dog assets you own. Depending on the rank of your Mad Dog (#1-9999) it will improve your car statistics (Speed, Acceleration, Handling, Braking). The higher ranked Mad Dog you have the better it will be for improving your cars.",
  },
  {
    title: "CATEGORY 2: CAR",
    text: "This multiplayer game mode will be for users to play against others for fun and earn achievements for their MD Racers profiles. Unranked mode will include 4, 6, and 8 player races.",
  },
  {
    title: "CATEGORY 3: CAR SKIN",
    text: "This multiplayer game mode will be for the skilled users who want to earn big. Players will be able to use their MD Tokens to stake into races against other players. There will be different tiers depending on the skill of the player. We will be having a monthly leaderboard which will display the players who have won the most races in ranked mode (top players for the month will be rewarded). Ranked mode will include 4, 6, and 8 player races.",
  },
];
const upcomingText = [
  {
    title: "Version 1.01",
    text: "Our first version of MD Racing will be released to our community on November 28th, 2022. Users will be able to download MD Racing to their desktop, create their own account and connect their wallet. Users will have access to the garage to customize their cars and utilize all assets they own such as Fully Assembled Cars, Mad Dogs, and Car Skins. Users will also have access to practice mode to play solo to learn car controls/physics and learn 3 tracks that have been made for racing when we release Unranked and Ranked mode in future versions. More tracks to be released in future updates.",
  },
  {
    title: "Version 1.02",
    text: "Our second version of MD Racing will be released in Q1 2022, we will introduce multiplayer for our community to race each other in Unranked Mode. The first version of Racer Profile will be released in this version as well. More details to be announced after V1.01 releases November 28th.",
  },
  {
    title: "Version 1.03",
    text: "Our third version of MD Racing will be released in Q2 2022, we will introduce ranked mode and integration of MD Tokens. Users will be able to use their MD Tokens to race against others in ranked races. There will also be achievements to earn through Racer Profiles to receive MD Tokens, NFT Airdrops, and Special Badges. More information to be released in Q1 2022.",
  },
];

const factsText = [
  "MD Modification Packs will be an important way of earning car accessories such as Car Skins which help improve your car. In the future we plan to release more car accessories such as wheels, spoilers, engines, and more! Each car accessory will be based on 4 Tiers, Diamond, Gold, Silver, Bronze. To earn MD Modification Packs you must be holding MD Racers. Every 3 months each MD Racer held will receive 1 MD Modification Package.",
  "We also plan on releasing a Rent-a-Car feature for those who hold many cars that are not in use. Holders can put their car up for rent and give opportunity to other users who don’t own cars or want to try a new car to test and play. We plan on releasing this update in Q3 of 2023.",
  "As of right now the only way to upgrade your car statistics is through owning Mad Dogs or Car Skin NFTs.",
];

const faqData = [
  {
    title: "WHAT IS MD RACING?",
    text: "MD Racing is a game built on Unreal Engine 5 with the utilizations of Mad Dog Car Club Assets.",
  },
  {
    title: "What modes are available in the game?",
    text: "We will have ranked mode, un-ranked mode, and practice.",
  },
  {
    title: "What are the functionalities of the game?",
    text: "You can connect your wallet, and play the game if you have the assets of MDCC that are needed.",
  },
  {
    title: "How can I control when playing the game?",
    text: "We will be making controller integrations but you can use the basic functionalities of the arrows on your laptop in order to accelerate, steer, and brake.",
  },
  {
    title: "What software will I have to download in order to play the game?",
    text: "You will not need to download software in order to play.",
  },
  {
    title: "When will the first version be released?",
    text: "V1.01 will be released November 28th, 2022. Users will be able to download MD RACING, connect their wallet, view all their NFT assets, customize their car, and race in practice mode on any of the 3 tracks playable.",
  },
  {
    title: "When will Multiplayer be accessible to be played?",
    text: "Multiplayer will be introduced in V1.02 coming out in Q1 2023. Unranked mode will be the first multiplayer mode we release where players can race against others in 4, 6, 8 player races.",
  },
  {
    title: "Will MD Racers be incorporated into the game?",
    text: "MD Racers will be incorporated in Q2 2023, this will be your 3D Identity in game to display in racer profile, as well as having your Mad Dog as your PFP.",
  },
];

const MDRacing = () => {
  // const [showMintModal, setShowMintModal] = useState(true);
  return (
    <div className="md-racing">
      <section className="about-section">
        <div className="container d-flex">
          <div className="d-flex flex-column align-items-center md-racing-logo">
            <img src={MdRacingImg} alt="md-racing-logo" />

            <div className="btn-panel d-flex flex-column justify-content-center align-items-center">
              <a
                href="assets/MD_Racing_v1.04.zip"
                target="_blank"
                className="btn btn-ranking font-nav font-bold d-flex align-items-center justify-content-center"

              >
                DOWNLOAD GAME
              </a>
              <p>**V1.04 Windows Compatible Only**</p>
            </div>
          </div>
        </div>
      </section>
      <section className="sales-section">
        <div className="text-panel container d-flex flex-column align-items-center">
          <p className="font-button1 font-bold text-center">
            MD Racing is our UE5 developed racing game which utilizes all of our
            NFT collections and brings mass utility to each and every individual
            asset.
          </p>
        </div>
        <div className="video-panel">
          <iframe
            src="https://player.vimeo.com/video/769654607?h=3ba60c4a06&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&loop=1&background=1&byline=0&title=0"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
        </div>
      </section>
      <section className="game-modes-section">
        <div className="container">
          <h1 className="font-h1 font-bold text-center">GAME MODES</h1>
          <div className="text-panel d-flex justify-content-between">
            {gameModesText.map((item, index) => (
              <div className="text-item flex-fill" key={index}>
                <h4 className="font-h4 font-medium">{item.title}</h4>
                <h5 className="font-h5 font-light">{item.text}</h5>
              </div>
            ))}
          </div>
          <img
            className="game-mode-img"
            src={GameModeImg}
            alt="game-mode-img"
          />
        </div>
      </section>
      <section className="garage-section">
        <div className="container">
          <h1 className="font-h1 font-bold text-center">GARAGE</h1>
          <h5 className="font-h5 font-light text-center">
            The garage will be a showroom to display and customize your assets.
            It will feature 12 spaces where you will have slots (A1-A12). Each
            slot will have 3 categories for customizing assets for racing in
            game (Mad Dog, Car, Car Skin). The main purpose of the garage is to
            customize the statistics and looks of your car. Each Car Model from
            our NFT collection is based on a scale of Speed, Acceleration,
            Handling, and Braking. To improve your cars stock statistics you
            will need a Mad Dog and Car Skin NFT. Combining all three assets
            together in a slot will give you your best statistics to start
            racing!
          </h5>
          <div className="text-panel d-flex justify-content-between">
            {garageText.map((item, index) => (
              <div className="text-item flex-fill" key={index}>
                <h4 className="font-h4 font-medium">{item.title}</h4>
                <h5 className="font-h5 font-light">{item.text}</h5>
              </div>
            ))}
          </div>
          <div className="images-panel d-flex justify-content-center">
            <img className="garage-img" src={GarageImg} alt="game-mode-img" />
          </div>
        </div>
      </section>
      <section className="racer-profile-section">
        <div className="container">
          <div className="text-panel">
            <h1 className="font-h1 font-bold text-center">RACER PROFILE</h1>
            <h5 className="font-h5 font-light text-center">
              Racer Profile will be your player card to showcase to other
              players in the community. It will display all the NFTs you own,
              your racing statistics with wins, earnings, achievements, your MD
              Racer which will be your 3D avatar identity in game, and all your
              friends. You will also have a customizable biography with a Mad
              Dog profile picture.
            </h5>
          </div>
          <div className="img-panel d-flex align-items-center justify-content-between">
            <img src={EllipseImg1} alt="ellipse-img" />
            <img src={EllipseImg2} alt="ellipse-img" />
            <img src={EllipseImg3} alt="ellipse-img" />
          </div>
        </div>
      </section>
      <section className="achievement-section">
        <div className="container">
          <div className="text-panel">
            <h1 className="font-h1 font-bold text-center">ACHIEVEMENTS</h1>
            <h5 className="font-h5 font-light text-center">
              We will have a variety of achievements for players to earn wether
              it’s a Mad Dog you own, or in-game quests. Earning achievements
              will grant users MD Tokens, NFT airdrops, and special badges to
              display on your Racer Profile. (More Details to be released as
              future updates come)
            </h5>
          </div>
          <div className="img-panel d-flex align-items-center justify-content-center">
            {["", "", "", "", ""].map((item, index) => (
              <img src={StarImg} alt="star-img" key={index} />
            ))}
          </div>
        </div>
      </section>
      <section className="upcoming-section">
        <div className="container">
          <div className="text-panel">
            <h1 className="font-h1 font-bold text-center">
              UPCOMING RELEASE OF MD RACING
            </h1>
            {upcomingText.map((item, index) => (
              <div className="text-item text-center">
                <p className="font-nav font-bold">{item.title}</p>
                <p className="font-nav font-light">{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="facts-section">
        <div className="container">
          <div className="text-panel">
            <h1 className="font-h1 font-bold text-center">
              IMPORTANT FACTS TO KNOW
            </h1>
            {factsText.map((item, index) => (
              <h5 className="font-h5 font-light text-center" key={index}>
                {item}
              </h5>
            ))}
          </div>
          <div className="d-flex justify-content-center">
            <a
              target="_blank"
              href="https://youtu.be/OCX9RYrnU-c"
              className="btn btn-preview font-nav font-bold"
            >
              SEE PREVIEW OF GAME
            </a>
          </div>
        </div>
      </section>
      <section className="faq-section">
        <div className="container">
          <h1 className="font-h1 font-bold text-center">
            FREQUENTLY ASKED QUESTIONS
          </h1>
          <div className="faq-text-panel">
            {faqData.map((faqItem, index) => (
              <div className="faq-item" key={index}>
                <h3 className="font-button1 font-bold">{faqItem.title}</h3>
                <p className="font-nav font-light">{faqItem.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <MintModal show={showMintModal} onClose={() => setShowMintModal(false)} /> */}
    </div>
  );
};

export default MDRacing;
